"use strict";

function initFades(form: HTMLFormElement) {
  form.querySelectorAll('.fade').forEach((div) => {
    setTimeout(() => div.classList.remove('show'), 3000);
    setTimeout(() => div.remove(), 3200);
  });
}

export default function initInlineForm(form: HTMLFormElement) {
  form.addEventListener('submit', onInlineFormSubmit);
  initFades(form);
}

async function onInlineFormSubmit(this: HTMLFormElement, event: Event)
{
  let form= this;
  event.preventDefault();
  const r = await fetch(form.dataset['action'] as string, {
    method:'POST',
    body: new FormData(form),
    headers: {"Accept": "application/json; charset=utf-8"}
  });
  if(r.ok) {
    const data = await r.json();
    if (data.redirect) {
      location.replace(data.redirect);
    }
    else if (data.html) {
      const parent = form.parentElement as HTMLElement;
      form.outerHTML = data.html;
      // @ts-ignore
      form = parent.querySelector('form');  // find replaced element
    }
    if (form !== null) {
      initInlineForm(form);  // for server-side displayed errors
    }
  } else {
    // Same effect as backend.
    form.insertAdjacentHTML( 'beforeend', "<div class='invalid-tooltip fade show'>Sorry er ging iets mis...</div>")
    initFades(form);
  }
}
